<template>
	<div>
		<wx-open-launch-weapp 
			id="launch-btn" 
			username="gh_7a520a05e35a" 
			:path="'/pages/soild/soild-sign-in?responseType=code&from=app&appName=xmzx&t=' + this.nowTimeStamp"
    	>
		 <script type="text/wxtag-template">
		 	<div>去注册</div>
		 </script>
		</wx-open-launch-weapp>
	</div>
</template>
<script>
		var btn = document.getElementById('launch-btn');
		btn.addEventListener('launch', function (e) {
			console.log('success',e);
		});
		btn.addEventListener('error', function (e) {
			console.log('fail', e.detail);
		});
		</script>
<script>
import {
	wxConfig
} from '../api/user';
export default {
	data() {
		return {
			nowTimeStamp:0,
		}
	},
	computed: {
	},
	watch: {
	},
	created() {

	},
	mounted() {
		wxConfig().then(res => {
			console.log("wxConfig",res)
			this.nowTimeStamp = res.timestamp,
			wx.config({
				debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.appId, // 必填，公众号的唯一标识
				timestamp: res.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.nonceStr, // 必填，生成签名的随机串
				signature: res.signature,// 必填，签名
				jsApiList:['chooseImage', 'previewImage'], //需要使用的JS接口列表,这里是分享到朋友圈和朋友的
				openTagList:['wx-open-launch-weapp']// 必填，需要使用的JS接口列表
			})
			wx.ready(() => {
				console.log('微信config执行')
			})
			wx.error(err => {
				console.log('微信config出错 ===', err)
				this.$utils.dialog.alert(`微信config失败：${err.errMsg}`)
			})
		})
		}
};
	
</script>

<style lang="less" scoped>
	
</style>
